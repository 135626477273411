import { render, staticRenderFns } from "./divider.vue?vue&type=template&id=567c3291"
import script from "./divider.vue?vue&type=script&lang=js"
export * from "./divider.vue?vue&type=script&lang=js"
import style0 from "./divider.vue?vue&type=style&index=0&id=567c3291&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../school_end/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports